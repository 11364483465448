export enum Phases {
  INSPECTION = '413fefe6-dae7-11e9-8a34-2a2ae2dbcce4',
  GRAPHIC_DESIGN = '457a50ba-dae7-11e9-9d36-2a2ae2dbcce4',
  BIG_FORMAT_PACKAGING = '6020d0ba-dae7-11e9-9d36-2a2ae2dbcce4',
  SMALL_FORMAT_PACKAGING = 'd0eec734-dae7-11e9-8a34-2a2ae2dbcce4',
  DATA_CHECK = '457a5600-dae7-11e9-9d36-2a2ae2dbcce4',
  DESIGN_APPROVE = '21f096fc-83f8-436d-90b5-1e4a5f0053b5',
  DIGITAL_BIG_FORMAT_PRINT = '511723e4-dae7-11e9-9d36-2a2ae2dbcce4',
  DIGITAL_SMALL_FORMAT_PRINT = 'a4de4bb0-dae7-11e9-8a34-2a2ae2dbcce4',
  SHAPED_PRE_PLOTTER = '51172c9a-dae7-11e9-9d36-2a2ae2dbcce4',
  PHOTOGRAPHY = 'ba77f328-f1f8-414f-ae15-bb936b82f3b0',
  COPYWRITE = 'dc496154-bd47-4aab-824d-23038c6dab62',
  ACCOUNT_PROCESSING = '721004d9-e258-4970-9740-4fe81c744a88',
  CUSTOMER_PROCESSING = '081e3207-101a-4d91-acd6-40fefde039df',
  PRE_PAYMENT = 'cc2c5eda-534c-4210-8d04-0844dcd8a2f4',
}
